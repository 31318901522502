import { Component, OnInit, ViewChild, OnChanges, Input , SimpleChanges } from '@angular/core';
import { NguCarousel, NguCarouselConfig } from '@ngu/carousel';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-carrousel',
  templateUrl: './carrousel.component.html',
  styleUrls: ['./carrousel.component.css']
})
export class CarrouselComponent implements OnInit {
  name = 'Angular';
  slideNo = 0;
  withAnim = true;
  resetAnim = true;

  images = [
    '../../assets/fleure1.PNG',
    '../../assets/fleure2.png',
    '../../assets/IMG_20211218_210235.jpg',
    '../../assets/IMG_20211218_210646.jpg',
    '../../assets/IMG_20211218_211529.jpg'
  ];
  public carouselTilesItems: Array<any> = [
    '../../assets/logo_2.jpg',
    '../../assets/logo_t.jpg',
    '../../assets/fleure1.PNG'
  ];

  constructor(private activated: ActivatedRoute) {}
  @Input() value: string;
  ngOnInit() {
    this.activated.params.subscribe(
      (data) => {
        console.log(data)
        this.imageLoad(data);
      }
    );
  }


 /* public carouselTileLoad() {
    for (let i = 0; i < 3; i++) {
      this.carouselTiles[i].push(
        this.images[i]
      );

    }
  }*/

  public imageLoad( theme ) {
    console.log(theme['cat'])
    if (theme['cat'] === 'mariage')
      this.images = [
        '../../assets/Mariage/m1.JPG',
        '../../assets/Mariage/m2.JPG',
        '../../assets/Mariage/m3.JPG'
    ];
    if (theme['cat'] === 'deuil')
    this.images = [
      '../../assets/Deuil/d1.JPG',
      // '../../assets/Deuil/d2.JPG',
      '../../assets/Deuil/d3.JPG',
      '../../assets/Deuil/d4.JPG',
    ];
    if (theme['cat'] === 'nais')
      this.images = [
        '../../assets/artfloral'
      ];
    if (theme['cat'] === 'bouquets')
      this.images = [
        '../../assets/Compo/b1.JPG',
        '../../assets/Compo/b2.JPG',
        '../../assets/Compo/b3.jpg'
    ];
    if (theme['cat'] === 'plantes')
      this.images = [
        '../../assets/Plantes/p1.jpg',
        '../../assets/Plantes/p2.jpg',
        '../../assets/Plantes/p3.jpg'
    ];
  }

  @ViewChild('myCarousel') myCarousel: NguCarousel<any>;
  carouselConfig: NguCarouselConfig = {
    grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
    load: 3,
    interval: {timing: 4000, initialDelay: 1000},
    loop: true,
    touch: true,
    velocity: 0.2,
    vertical: {enabled: false, height: 340 }
  }
}
