<div class="myDiv">
  <div class="globaldiv">
      <ngu-carousel #myCarousel [inputs]="carouselConfig" [dataSource]="images">
        <div *nguCarouselDef="let item;" class="item">
          <div class="tile" [style.background]="'url('+item+')'"></div>
        </div>
        <button NguCarouselNext class="rightRs">>></button>
        <button NguCarouselPrev class="leftRs"><<</button>
        <ul class="myPoint" NguCarouselPoint>
          <li *ngFor="let j of myCarousel.pointNumbers; let ind = index" [class.active]="j==myCarousel.activePoint" (click)="myCarousel.moveTo(j)"></li>
        </ul>
      </ngu-carousel>
  </div>
</div>



