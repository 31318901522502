import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccueilComponent } from './accueil/accueil.component';
import { CarrouselComponent } from './carrousel/carrousel.component';
import { LivraisonComponent } from './livraison/livraison.component';
import { CompoComponent } from './compo/compo.component';


const routes: Routes = [
  { path: 'accueil', component: AccueilComponent },
  { path: 'compo', component: CompoComponent },
  { path: 'carrousel/:cat', component: CarrouselComponent },
  { path: 'livraison', component: LivraisonComponent },
  { path: '', component: AccueilComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
