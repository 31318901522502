<div>

    <img class="leftpanel" src="../../assets/visuel.jpg"/>

    <!-- <div ng-app="demo" ng-controller="myCtrl"> -->
    <textarea class="text" cols="24" wrap>
    Livraison possible
    Pour plus de renseignements,
    veuillez nous contacter au
    03 85 75 03 57
    </textarea>
  <div>
    <img class="imgmap" src="../../assets/map_zone.PNG"/>
  </div>


</div>


