<div class="menu">
  <img title="logo" style="width:200px; height:120px; margin: 10px" src="../../assets/logo_2.png">

  <button mat-button [matMenuTriggerFor]="aboveMenu"  routerLink="/accueil">Magasin</button>
  <mat-menu #aboveMenu="matMenu" >
    <button mat-menu-item>Item 1</button>
    <button mat-menu-item>Item 2</button>
  </mat-menu>

  <button mat-button [matMenuTriggerFor]="belowMenu">Compositions</button>
  <mat-menu #belowMenu="matMenu" >
    <button mat-menu-item routerLink="/carrousel">Bouquets</button>
    <button mat-menu-item routerLink="/carrousel">Plantes vertes</button>
  </mat-menu>

  <button mat-button [matMenuTriggerFor]="beforeMenu">Theme</button>
  <mat-menu #beforeMenu="matMenu" >
    <button mat-menu-item routerLink="/carrousel">Mariage</button>
    <button mat-menu-item routerLink="/carrousel">Naissance</button>
    <button mat-menu-item routerLink="/carrousel">Deuil</button>
  </mat-menu>

  <button mat-button [matMenuTriggerFor]="afterMenu">Livraison</button>
  <mat-menu #afterMenu="matMenu" >
    <button mat-menu-item routerLink="/livraison">Livraison</button>
  </mat-menu>
</div>
