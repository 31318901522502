import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-compo',
  templateUrl: './compo.component.html',
  styleUrls: ['./compo.component.css']
})
export class CompoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
