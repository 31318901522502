<div class="menu">
    <img title="logo" style="width:50px; height:30px; margin: 10px; float:left" src="../../assets/logo_simple.png">
    <img title="logo" style="width:50px; height:30px; margin-top: 100px; float:left" src="../../assets/logo_simple_2.png">
    <img title="logo" style="width:50px; height:30px; margin-top: 50px; float:left" src="../../assets/logo_simple.png">
    <img title="logo" style="width:40px; height:26px; margin-left: 10px; margin-top: 16px; float:left" src="../../assets/logo_simple_3.png">
    <button class="title" mat-button routerLink="/accueil">Magasin</button>
    <!--button class="title" mat-button [matMenuTriggerFor]="aboveMenu"  routerLink="/accueil">Magasin</button>
    <mat-menu #aboveMenu="matMenu" >
        <button mat-menu-item>Item 1</button>
        <button mat-menu-item>Item 2</button>
    </mat-menu-->

    <button class="title" mat-button [matMenuTriggerFor]="belowMenu">Compositions</button>
    <mat-menu #belowMenu="matMenu" >
        <button mat-menu-item routerLink="/carrousel/bouquets">Bouquets</button>
        <button mat-menu-item routerLink="/carrousel/plantes">Plante verte</button>
    </mat-menu>

    <button class="title" mat-button [matMenuTriggerFor]="beforeMenu">Theme</button>
    <mat-menu #beforeMenu="matMenu" >
        <button mat-menu-item routerLink="/carrousel/mariage">Mariage</button>
        <button mat-menu-item routerLink="/carrousel/nais">Naissance</button>
        <button mat-menu-item routerLink="/carrousel/deuil">Deuil</button>
    </mat-menu>

    <button class="title" mat-button [matMenuTriggerFor]="afterMenu">Livraison</button>
    <mat-menu #afterMenu="matMenu" >
        <button mat-menu-item  routerLink="/livraison">Livraison</button>
    </mat-menu>

    <img title="logo" style="width:200px; height:120px; margin: 10px; float:right" src="../../assets/logo_new.png">
</div>
