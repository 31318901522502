<!DOCTYPE html>
<html>

<head>
    <!--meta name="viewport" content="width=device-width, initial-scale=1.0"-->
    <!--link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.1.3/css/bootstrap.min.css"-->
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/ionicons/2.0.1/css/ionicons.min.css">
    <!--link rel="stylesheet" href="assets/css/style.css"-->
</head>

<body>
    <div class="footer-dark">
        <footer>
            <div class="container">
                <div class="row">
                    <div class="leftPanel">
                        <h3>Plan du site</h3>
                        <ul>
                            <li><a href="/accueil">Magasin</a></li>
                            <li><a href="/carrousel/bouquets">Compositions</a></li>
                            <li><a href="/carrousel/mariage">Theme</a></li>
                            <li><a href="/livraison">livraison</a></li>
                        </ul>
                    </div>
                    <div class="middlePanel">
                        <h3>Services</h3>
                        <ul>
                            <li><a href="/livraison">Livraison</a></li>
                            <li><a href="#">InterFloral</a></li>
                            <li><a href="#">JetFleur</a></li>
                        </ul>
                    </div>
                    <div class="rightPanel">
                        <h3>Magasin</h3>
                        <p class="addresse">21 grande rue 71500 Louhans <br><br>
                            9h00-19h00 lundi au samedi <br> 9h00-13h00 le dimanche</p>
                    </div>
                </div>
                <div class="col item social"><a href="#"><i class="icon ion-social-facebook"></i></a></div>
                <p class="copyright">Company Name © 2021</p>
            </div>
        </footer>
    </div>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.1.3/js/bootstrap.bundle.min.js"></script>
</body>
</html>
