import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuComponent } from './menu/menu.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { CoreComponent } from './core/core.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { AccueilComponent } from './accueil/accueil.component';
import { CompoComponent } from './compo/compo.component';
import { CarrouselComponent } from './carrousel/carrousel.component';
import { NguCarouselModule } from '@ngu/carousel';
import {VERSION as MAT_VERSION, MatNativeDateModule} from '@angular/material/core';
import { CommonModule } from '@angular/common';
import { LivraisonComponent } from './livraison/livraison.component';

console.info('Angular Material version', MAT_VERSION.full);

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    HeaderComponent,
    FooterComponent,
    CoreComponent,
    AccueilComponent,
    CompoComponent,
    CarrouselComponent,
    LivraisonComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatMenuModule,
    NguCarouselModule,
    MatNativeDateModule,
    CommonModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
