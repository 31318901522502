import { Component, OnInit } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';

interface Car {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-livraison',
  templateUrl: './livraison.component.html',
  styleUrls: ['./livraison.component.css']
})
export class LivraisonComponent implements OnInit {

  constructor() { this.lieux=["Branges","Sornay","Louhans"];}
  cars: Car[] = [
    {value: 'volvo', viewValue: 'Volvo'},
    {value: 'saab', viewValue: 'Saab'},
    {value: 'mercedes', viewValue: 'Mercedes'},
  ];
  lieux=["Branges","Sornay","Louhans"];
  prov1: string;
  ngOnInit() {
    this.lieux=["Branges","Sornay","Louhans"];
    console.log(this.lieux)
      }


}
